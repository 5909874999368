$animation-speed: .3s;
$animation-function: ease-out;
$backdrop-color: rgba(0, 0, 0, .5);
body {
  margin: 0px auto;
  background-color: #0f1b33 !important;
  color: white !important;
}

@font-face {
  src: url("../fonts/Oxanium-Regular.ttf");
  font-family: Oxanium-Regular;
}

@font-face {
  src: url("../fonts/Oxanium-Bold.ttf");
  font-family: Oxanium-Bold;
}
@font-face {
  src: url("../fonts/Poppins-Regular.ttf");
  font-family: Poppins-Regular;
}
th,
td,
tr,
.alert,
ul,
p,
a,
span,::placeholder {
  font-family: Oxanium-Regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
label,
strong {
  font-family: Oxanium-Bold;
}
.line{
  border-bottom: 1px solid #243048;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #0f1b33 !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.is-sticky .miniHeader{
  display: none;
}
.navbar {
  background-color: #061431;
  padding: 25px 0px !important;
  position: fixed !important;
  width: 100%;
  z-index: 9999;
  .navbar-brand {
    img {
      width: 220px;
    }
  }
  .navbar-collapse {
    justify-content: flex-end;
}
  .navbar-toggler {
    border-color: #1071fe !important;
    background: linear-gradient(180deg, #01bae3 0%, #1072fd 100%) !important;
    box-shadow: 0px 4px 24px #01b8e359 !important;
    .navbar-toggler-icon {
        filter: invert(1) !important;
    }
}
 @media screen and (max-width:768px) {
    .navbar-collapse {
        background: #060d1ec7;
        padding: 0px 15px;
        border-radius: 10px;
        margin-top: 10px;
    }
 }
 .custom-margin{
  margin-right: 70px !important;
  @media screen and (max-width:1440px) {
  margin-right: 100px !important;
 }
 @media screen and (max-width:575px) {
  margin-right: 0px !important;
 }
 }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    @media screen and (max-width:768px){
       display: block;
       height: 215px;
    overflow-y: scroll;
    }
    li {
      list-style: none;
      @media screen and (max-width:768px){
        padding: 15px;
     }
      a {
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        text-transform: capitalize;
        padding: 12px;
        margin: 3px;
        display: inline-block;
        &:hover{
          border-bottom: 3px solid #01bae3;
        }
      }
    }
  }

  .search-box {
    position: absolute;
    background: #061431;
    height: 60px;
    border-radius: 40px;
    padding: 10px;
    @media screen and (max-width:768px){
       position: relative;
       top: 0;
       bottom: 0;
       transform: translate(0%,0%);
       right: 0;
       margin-bottom: 15px;
       margin-top: 10px;
    }
    &:hover {
      & > .search-txt {
        width: 330px;
        padding: 0 6px;
        border: 1px solid white;
        border-radius: 10px;
        margin-right: 5px;
        outline: none;
        color: #061431;
        @media screen and (max-width:768px) {
          width: 80%;
          border: 1px solid #061431;
          color: #ffffff;
          background: #061431;
        }
      }
      & > .search-btn {
        background: white;
      }
    }
  }

  .search-btn {
    color: #01bae3;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;
  }
  .search-txt {
    border: none;
    outline: none;
    float: left;
    padding: 0;
    color: #fff;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
  }
}

.miniHeader {
  overflow: hidden;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #01bae3 0%, #1071fe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
    padding: 25px 0px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 25px;
      padding: 12px 10px;
    }
  }
}

.homeSection {
  background:
    url(../images/home-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .innerSection {
    display: flex;
    align-items: center;
    justify-content: center;
        padding: 70px 0px;
        padding-top: 170px;
        height: 690px;
    @media screen and (max-width: 575px) {
       height: 600px;
      }
    .homeContent {
      h1 {
        font-weight: 800;
        font-size: 64px;
        line-height: 76px;
        text-transform: capitalize;
        @media screen and (max-width: 768px) {
          font-size: 32px;
          line-height: 40px;
        }
      }
      p {
        font-family: Poppins-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #ffffff;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 26px;
        }
      }
      .learnPlay {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 35px;
        @media screen and (max-width: 375px) {
          margin-top: 30px;
          display: inline-block;
        }
        .learnBtn {
         
          button {
            background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
            border-radius: 3px;
            font-weight: 700;
            font-size: 16px;
            line-height: 15px;
            text-transform: capitalize;
            color: #ffffff;
            text-decoration: none;
            padding: 15px 30px;
            position: relative;
            display: inline-grid;
            width: 160px;
            height: 47px;
            outline: 0;
            border: 0px;
            box-shadow: 0px 0px 15px 2px #0791ed91;
          }
        }
        .playBtn {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-left: 30px;
          @media screen and (max-width: 375px) {
            margin-left: 0;
            margin-top: 35px;
          }
          .playIcon{
            img{
              -webkit-transition: 0.4s ease;
              transition: 0.4s ease;
              cursor: pointer;
              &:hover{
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
              }
            }
          }
          .playtext {
            margin-left: 15px;
            p {
              margin: 0;
              font-weight: 700;
              font-size: 16.5px;
              line-height: 20px;
              letter-spacing: 0.27em;
              color: #ffffff;
            }
          }
        }
      }
    }
    .social_media_div {
      position: fixed;
      right: 35px;
      top: 60%;
      transform: translate(0px, -50%);
      z-index: 9999;
      @media screen and (max-width:768px) {
        display: none !important;
      }
      li {
        list-style: none;
        margin: 15px 0px;
        a {
          text-decoration: none;
          i {
            text-decoration: none;
            color: #ffffff99;
            border-radius: 30px;
            font-weight: bold;
            transition: all 0.4s;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
          &:hover {
            i {
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -ms--transform: rotate(360deg);
              transform: rotate(360deg);
              -webkit-transition: all 0.4s;
              -moz-transition: all 0.4s;
              -o-transition: all 0.4s;
              -ms-transition: all 0.4s;
              transition: all 0.4s;
            }
          }
        }
      }
    }
    .facebook-icon {
      background-color: transparent;
      border: 1px solid #ffffff99;
      &:hover{
        background: #4267B2;
        color: white;
        border: 1px solid white;
      }
    }
    .twitter-icon {
      background-color: transparent;
      border: 1px solid #ffffff99;
      &:hover{
        background: #00acee;
        color: white;
        border: 1px solid white;
      }
    }
    .instagram-icon {
      background-color: transparent;
      border: 1px solid #ffffff99;
      &:hover{
        background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
        color: white;
        border: 1px solid white;
      }
    }
  }
}

.releasedApp{
    padding: 70px 0px;
    overflow: hidden;
    .title{
        width: 40%;
        margin: 0 auto;
        text-align: center;
        @media screen and (max-width:768px) {
            width: 70%;
        }
        @media screen and (max-width:575px) {
            width: 95%;
        }
        h1{
            font-weight: 800;
            font-size: 48px;
            line-height: 50px;
            text-align: center;
            text-transform: capitalize;
            color: #FFFFFF;
            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 40px;
              }
        }
        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 26px;
              }
        }
    }
    .slick-slider{
        margin-top: 50px;
        .slick-arrow{
            display: none !important;
        }
    }
    .app-card{
      position: relative;
      @media screen and (max-width:1200px) {
       margin: 5px;
     }
     @media screen and (max-width:768px) {
      margin: 0px;
    }
       img{
        height: 400px;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
       }
        .app-content{
            position: absolute;
            bottom: 20px;
            left: 25px;
            right: 25px;
            @media screen and (max-width:1200px) {
              left: 15px;
            right: 15px;
           }
           @media screen and (max-width:768px) {
            left: 25px;
          right: 25px;
         }
            a{
                font-weight: 700;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #FFFFFF;
                background: linear-gradient(180deg, #02B5E5 0%, #0F79FB 100%);
                border-radius: 2px;
                padding: 8px 30px;
                text-decoration: none;
                span {
                  cursor: pointer;
                  display: inline-block;
                  position: relative;
                  transition: 0.5s;
                }
                span:after {
                  content: '\00bb';
                  position: absolute;
                  opacity: 0;
                  top: 0;
                  right: -20px;
                  transition: 0.5s;
                }
                &:hover{
                  box-shadow: 0px 4px 24px rgb(1 184 227 / 35%);
                  span{
                    padding-right: 17px;
                    &::after{
                      opacity: 1;
                      right: 0;
                    }
                  }
                }
            }
            h3{
                font-weight: 700;
                font-size: 23px;
                line-height: 32px;
                text-transform: capitalize;
                color: #FFFFFF;
                margin: 13px 0px;
            }
            .rating{
                display: flex;
                flex-wrap: wrap;
                i{
                    color:#FFC436;
                    font-size: 11px;
                    margin-right: 5px;
                }
                p{
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                }
            }
           
        }
    }
}

.modal-content{
  background-color: #aa00d5 !important;
  .btn-close{
    filter: invert(1) !important;
  }
}
.bigMatch{
    background:
      url(../images/big-match-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0px;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    .title{
      text-align: center;
        h1{
            font-weight: 800;
            font-size: 48px;
            line-height: 50px;
            text-align: center;
            text-transform: capitalize;
            color: #FFFFFF;
            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 40px;
              }
        }
        h2{
          font-weight: 800;
            font-size: 65px;
            line-height: 72px;
            margin-top: 15px;
            text-align: center;
            text-transform: capitalize;
            color: #FFFFFF;
            @media screen and (max-width: 768px) {
              font-size: 55px;
              line-height: 55px;
              }
            @media screen and (max-width: 575px) {
              font-size: 37px;
              line-height: 35px;
              margin-top: 0px;
              }
        }
        img{
          width: 100%;
          @media screen and (max-width: 768px) {
           width: 80%;
          }
        }
        p{
            font-weight: 500;
            font-size: 22px;
            line-height: 18px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 10px;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 26px;
              }
        }
    }
    .timer{
        display: flex;
        text-align: center;
        justify-content: center;
        .timeBox{
            padding: 4px;
            .time{
                font-weight: 700;
                font-size: 27.375px;
                line-height: 23px;
                color: #FFFFFF;
                background: linear-gradient(180deg, #04AFE7 0%, #107BFA 100%);
                border-radius: 3.04167px 3.04167px 0px 0px;
                width: 65px;
                height: 51px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            h2{
                font-weight: 700;
                font-size: 10.6458px;
                line-height: 11px;
                text-transform: capitalize;
                color: #1C2029;
                background: #FFFFFF;
                border-radius: 0px 0px 3.04167px 3.04167px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }
      }
      .watchVideo{
        text-align: center;
        margin-top: 25px;
        .modal-btn{
          background: linear-gradient(180deg, #03B1E6 0%, #107AFA 100%);
          border-radius: 1.52083px;
          font-weight: 700;
          font-size: 12.1667px;
          line-height: 11px;
          text-transform: capitalize;
          color: #FFFFFF;
          padding:14px 25px;
          border: none;
         
          img{
            margin-left: 8px;
          }
          span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
          }
          span:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: 2px;
            right: -20px;
            transition: 0.5s;
          }
          &:hover{
            box-shadow: 0px 4px 24px rgb(1 184 227 / 35%);
            span{
              padding-right: 17px;
              &::after{
                opacity: 1;
                right: 0;
              }
            }
          }
        }
      }
}

.app-slider {
  background-color: #0F1B33;
  overflow: hidden;
  .over{
    width: 100%;
    height: 280px;
    overflow: hidden;
    margin-bottom: 80px;
    @media screen and (max-width:992px) {
        width: 100%;
    }
    @media screen and (max-width:768px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width:575px) {
      height: 410px;
    }
    img {
      object-fit: cover;
      transition: 0.3s;
      width: 100%;
      height: 100%;
      &:hover{
        transform: scale(1.1);
      }
  }
  }

  .recomended-content{
    overflow: hidden;
    margin-left: 20px;
    margin-right: 20px;
    @media screen and (max-width:768px) {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 45px;
    }
    .playStore{
      @media screen and (max-width:575px) {
        text-align: center;
      }
      a{
        img{
          width: 135px;
          height: auto;
          margin: 0;
          margin-top: 30px;
          object-fit: contain;
          @media screen and (max-width:575px) {
            margin: 0 auto;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .slider-btn {
      background: linear-gradient(180deg, #03B1E6 0%, #1077FB 100%);
      height: 45px;
      width: 130px;
      border: none;
      color: #fff;
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 30px;
      justify-content: center;
      @media screen and (max-width:575px) {
        margin: 0 auto;
        margin-top: 30px;
      }
  }

  h3 {
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      text-align: left;
      padding: 30px 0px;
      padding-top: 60px;
      color: #FFFFFF;
      font-family: Poppins-Regular;
      @media screen and (max-width:575px) {
        font-weight: 800;
        font-size: 26px;
        margin-bottom: 55px;
        line-height: 40px;
      }
  }

  h2 {

    font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      text-align: left;
      line-height: 36px;
      margin-bottom: 12px;
      color: #FFFFFF;
      cursor: pointer;
      span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }
      span:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: -25px;
        right: -20px;
        transition: 0.5s;
      }
      &:hover{
        span{
          padding-right: 25px;
          &::after{
            opacity: 1;
            right: 0;
          }
        }
      }
  }

  h6 {
      color: #0b87e5;
      text-align: left;
  }

  p {
    font-family: Poppins-Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      color: #ffffff99;
      margin-top: 20px;
  }
}

.slick-next{
  top: -15% !important;
  height: 50px !important;
  width: 50px !important;
  display: flex !important;
  align-items: center; 
  justify-content: center;
  z-index: 9;
  overflow: hidden;
  &::before{
    content: url(../images/right-arrow.svg) !important;
    line-height: 0 !important;
  }
  @media screen and (max-width:992px){
    top: -5% !important;
    right: 25px !important;
  }
  @media screen and (max-width:767px){
    top: -2% !important;
  }
  &:hover{
    background: #1077fb !important;
  }
}

.slick-prev{
  top: -15% !important;
  height: 50px !important;
  width: 50px !important;
  display: flex !important;
  align-items: center; 
  justify-content: center;
  right: 40px;
  z-index: 9;
  left: auto !important;
  overflow: hidden;
  &::before{
    content: url(../images/left-arrow.svg) !important;
    line-height: 0 !important;
  }
  @media screen and (max-width:992px){
    top: -5% !important;
    right: 85px !important;

  }
  @media screen and (max-width:767px){
    top: -2% !important;
  }
  &:hover{
    background: #1077fb !important;
  }
}

.signup {
  background-color: #09162F;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  overflow: hidden;
  @media screen and (max-width:575px) {
    padding: 50px 0px;
   }

h2 {
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  margin-bottom: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;

  @media screen and (max-width:1400px) {
      font-size: 37px;
  }

  @media screen and (max-width:1200px) {
      font-size: 33px;
  }

  @media screen and (max-width:992px) {
      font-size: 26px;
      line-height: 40px;
  }
}

input {
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  padding-bottom: 5px;
  border-right: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #FFFFFF;
  &::placeholder{
          font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #FFFFFF;
  }
  &:focus{
      background: transparent;
      box-shadow: none;
      color: #FFFFFF;
  }
}

.input-group-text {
  background: linear-gradient(180deg, #04ACE8 0%, #107BFA 100%);
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  border: 1px solid #04ACE8;
  cursor: pointer;
  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  &:hover{
    box-shadow: 0px 4px 24px rgb(1 184 227 / 35%);
    span{
      padding-right: 25px;
      &::after{
        opacity: 1;
        right: 0;
      }
    }
  }
}
}

.footer {
  background-image: url(../images/BG.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0px;
  overflow: hidden;
  @media screen and (max-width:575px) {
    padding: 40px 0px;
    padding-bottom: 25px;
  }
  h2 {
      font-weight: 600;
      text-align: left;
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 30px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin-top: 5px;

      @media screen and (max-width:575px) {
          text-align: center;
      }
      @media screen and (max-width:375px) {
          font-size: 19px;
      }


  }
  .footer-link{
    @media screen and (max-width:768px) {
      height: 60px;
     }
  }
  img {
      width: 220px;

  }

  .links {
      text-decoration: none;
      list-style: none;
      padding: 0px;
      text-align: left;
      margin-top: 20px;

      @media screen and (max-width:575px) {
          text-align: center;
      }

      p {
          text-align: left;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;

          @media screen and (max-width:475px) {
              padding-top: 20px;
          }
      }

      li {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 40px;
          color: #FFFFFF;
          span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
          }
          span:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: 0;
            right: -20px;
            transition: 0.5s;
          }
          &:hover{
            span{
              padding-right: 18px;
              &::after{
                opacity: 1;
                right: 0;
              }
            }
          }
      }
  }

  .gallary {
      padding: 0px;

      img {
        width: 30.333%;
        border-radius: 5px;
        height: 100px;
        object-fit: cover;
        margin: 3px !important;
          @media screen and (max-width:768px) {
           height: 170px;
          }
          @media screen and (max-width:575px) {
            height: 145px;
          }
      }
  }

  .social {
    .social_media_div {
       ul{
        display: flex;
        gap: 20px;
        padding: 0;
        @media screen and (max-width:575px) {
          justify-content: center;
      }
        li {
          list-style: none;
          margin: 15px 0px;
          a {
            text-decoration: none;
            i {
              text-decoration: none;
              color: #ffffff99;
              border-radius: 30px;
              font-weight: bold;
              transition: all 0.4s;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
            }
            &:hover {
              i {
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -ms--transform: rotate(360deg);
                transform: rotate(360deg);
                -webkit-transition: all 0.4s;
                -moz-transition: all 0.4s;
                -o-transition: all 0.4s;
                -ms-transition: all 0.4s;
                transition: all 0.4s;
              }
            }
          }
        }
       }
      }
      .facebook-icon {
        background-color: transparent;
        border: 1px solid #ffffff99;
        &:hover{
          background: #4267B2;
          color: white;
          border: 1px solid white;
        }
      }
      .twitter-icon {
        background-color: transparent;
        border: 1px solid #ffffff99;
        &:hover{
          background: #00acee;
          color: white;
          border: 1px solid white;
        }
      }
      .instagram-icon {
        background-color: transparent;
        border: 1px solid #ffffff99;
        &:hover{
          background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
          color: white;
          border: 1px solid white;
        }
      }
      .playStore-icon {
        background-color: transparent;
        border: 1px solid #ffffff99;
        &:hover{
          background: radial-gradient(circle farthest-corner at 35% 90%, #ff3333
          , transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #ff3333
          , transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #ffd400, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #ffd400, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #3bccff, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #3bccff, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #48ff48
          , transparent), linear-gradient(#3bccff, #48ff48
          30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
          color: white;
          border: 1px solid white;
        }
      }
      img {
          max-width: 40px;
          max-height: 40px;
          display: flex;
          margin-top: 10px;
          width: 100%;
          @media screen and (max-width:768px) {
              margin-bottom: 30px;
          }
      }
  }

}

.copywrite {
  background-color: #09162F;
  padding: 30px 0px;
  p {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
      margin: 0px;
      color: #FFFFFF;
  }
}

.privacyPolicy{
  padding: 70px 0px;
  padding-top: 190px;
  @media screen and (max-width: 768px) {
    padding: 40px 0px;
    padding-top: 155px;
  }
  .heading{
    margin-top: 40px;
    h2{
      font-size: 30px;
    }
  }
  .para{
    p{
      font-size: 15px;
    }
    ul{
      li{
        font-size: 15px;
      }
    }
    a{
      font-size: 15px;
      color: #00bae2;
      text-decoration: none;
    }
  }
}
/* 12 */
.btn-12 {
  background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
  border-radius: 2px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  padding: 15px 30px;
  position: relative;
	-webkit-perspective: 230px;
	perspective: 230px;
	span {
		background: rgb(0,172,238);
		background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
		display: block;
		position: absolute;
    display: inline-grid;
    width: 160px;
    height: 47px;
    outline: 0;
    border: 0px;
		box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
		border-radius: 3px;
		margin: 0;
		text-align: center;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-transition: all .3s;
		transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
		&:nth-child(1) {
			-webkit-transform: rotateX(90deg);
			-moz-transform: rotateX(90deg);
			transform: rotateX(90deg);
			-webkit-transform-origin: 50% 50% -20px;
			-moz-transform-origin: 50% 50% -20px;
			transform-origin: 50% 50% -20px;
		}
		&:nth-child(2) {
			-webkit-transform: rotateX(0deg);
			-moz-transform: rotateX(0deg);
			transform: rotateX(0deg);
			-webkit-transform-origin: 50% 50% -20px;
			-moz-transform-origin: 50% 50% -20px;
			transform-origin: 50% 50% -20px;
		}
	}
	&:hover {
		span {
			&:nth-child(1) {
				box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
				-webkit-transform: rotateX(0deg);
				-moz-transform: rotateX(0deg);
				transform: rotateX(0deg);
        z-index: 999;
			}
			&:nth-child(2) {
				box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
				color: transparent;
				-webkit-transform: rotateX(-90deg);
				-moz-transform: rotateX(-90deg);
				transform: rotateX(-90deg);
			}
		}
	}
}

@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

$animation-speed: .3s;
$animation-function: ease-out;
$backdrop-color: rgba(0, 0, 0, .5);

@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-video-inner {
	from {
		transform: translate(0, 100px);
	}

	to {
		transform: translate(0, 0);
	}
}

.modal-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $backdrop-color;
	z-index: 1000000;
	cursor: pointer;
	opacity: 1;
	animation-timing-function: $animation-function;
	animation-duration: $animation-speed;
	animation-name: modal-video;
	-webkit-transition: opacity $animation-speed $animation-function;
	-moz-transition: opacity $animation-speed $animation-function;
	-ms-transition: opacity $animation-speed $animation-function;
	-o-transition: opacity $animation-speed $animation-function;
	transition: opacity $animation-speed $animation-function;
}

.modal-video-effect-exit {
	opacity: 0;

	& .modal-video-movie-wrap {
		-webkit-transform: translate(0, 100px);
		-moz-transform: translate(0, 100px);
		-ms-transform: translate(0, 100px);
		-o-transform: translate(0, 100px);
		transform: translate(0, 100px);
	}
}

.modal-video-body {
	max-width: 960px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 10px;
    display: flex;
    justify-content: center;
	box-sizing: border-box;
}

.modal-video-inner {
	display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
	height: 100%;

    @media (orientation: landscape) {
        padding: 10px 60px;
        box-sizing: border-box;
    }
}

.modal-video-movie-wrap {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	background-color: #333;
	animation-timing-function: $animation-function;
	animation-duration: $animation-speed;
	animation-name: modal-video-inner;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: -webkit-transform $animation-speed $animation-function;
	-moz-transition: -moz-transform $animation-speed $animation-function;
	-ms-transition: -ms-transform $animation-speed $animation-function;
	-o-transition: -o-transform $animation-speed $animation-function;
	transition: transform $animation-speed $animation-function;

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.modal-video-close-btn {
	position: absolute;
	z-index: 2;
	top: -45px;
	right: 0px;
	display: inline-block;
	width: 35px;
	height: 35px;
	overflow: hidden;
	border: none;
	background: transparent;

    @media (orientation: landscape) {
        top: 0;
        right: -45px;
    }

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #fff;
		border-radius: 5px;
		margin-top: -6px;
	}
}
